.app {
  text-align: center;
  font-family: "Inter-medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 1.4;
}

.app__container {
  padding: 32px 40px 24px;
  box-sizing: border-box;
  max-width: 1360px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.app__content {
  flex: 1;
}
