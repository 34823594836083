.news__section {
  font-size: 20px;
  font-weight: 500;
  font-family: "Inter-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
}

.news__items {
  display: flex-column;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
