.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  min-height: 40px;
  font-family: "Inter-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI Bold",
    Roboto, "Helvetica Neue Bold", Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-bottom: #b6bcbf 2px solid;
}

.header__link {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.header__logo {
  border-radius: 50px;
  transition: transform 0.3s ease;
}
.header__logo:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.header__image {
  padding-top: 3px;
  padding-left: 2px;
  padding-right: 2px;
  width: 65px;
  height: 65px;
  object-fit: cover;
}

.header__button {
  border-width: 0;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  padding: 0;
}

.header__button:hover {
  transform: scale(1.1);
}

.header__button_border {
  height: 60px;
  width: 129px;
  padding: 0;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  border-color: #2f71e5;
  background-color: #fff;
  color: #2f71e5;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.header__button_border:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.header__menu {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header__tab {
  color: #000;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.header__tab:hover {
  transform: scale(1.1);
}

.header__menu-mobile-icon {
  border-style: none;
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
}

@media screen and (max-width: 640px) {
  .header {
    margin-bottom: 16px;
    position: relative;
  }

  .header__mobile-opened {
    align-items: flex-start;
    position: relative;
  }

  .header__menu {
    gap: 10px;
  }

  .header__menu_mobile {
    border-radius: 10px;
    row-gap: 20px;
    column-gap: 0;
  }

  .header__button_border {
    border-width: 0;
    width: 80px;
  }

  .header__menu_mobile-closed .header__button,
  .header__menu_mobile-closed .header__user,
  .header__menu_mobile-closed .header__tab {
    display: none;
  }

  .header__burger-show {
    display: unset;
    margin-left: 12px;
  }

  .header__close-button-show {
    display: unset;
  }

  .header__menu_mobile-opened {
    background-color: #fff;
    flex-direction: column;
    position: absolute;
    padding: 25px 0;
    width: 100%;
    z-index: 1;
  }
}
