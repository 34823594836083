@font-face {
  src: url("./fonts/Inter-Bold.woff2") format("woff2");
  font-family: "Inter-Bold";
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  src: url("./fonts/Inter-Medium.woff2") format("woff2");
  font-family: "Inter-Medium";
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  src: url("./fonts/Inter-Regular.woff2") format("woff2");
  font-family: "Inter-Regular";
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
