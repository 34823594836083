.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__content {
  border-radius: 30px;
  font-family: "Inter-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  background-color: #fff;
  width: 400px;
  padding: 35px;
  position: relative;
}

.modal__title {
  font-family: "Inter-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI Bold",
    Roboto, "Helvetica Neue Bold", Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
  text-align: left;
}

.modal__close-button {
  border-style: none;
  background: url("../../images/closeButtonGray.svg");
  width: 20px;
  height: 20px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.modal__form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.modal__form-inputs {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.modal__form-input {
  height: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: #cfcfcf;
  border-radius: 30px;
  background-color: transparent;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 15px;
}

.modal__form-textarea {
  height: 240px;
}

.modal__form-radio-header {
  margin-top: 15px;
  margin-bottom: 5px;
}

.modal__radio {
  color: rgba(0, 0, 0, 0.5);
}

.modal__radio-input {
  margin-top: 8px;
  cursor: pointer;
}

.modal__radio-input:checked ~ span {
  color: black;
}

.modal__submit-addOn {
  display: flex;
  align-items: center;
  gap: 20px;
}
.modal__submit-addOn-text {
  margin-top: 15px;
}

.modal__submit-button {
  border-style: none;
  width: 129px;
  height: 50px;
  border-radius: 30px;
  background-color: #8cace5;
  color: #fff;
  font-weight: 500;
  transition: ease-in 0.3s;
  cursor: pointer;
  margin-top: 15px;
}

.modal__submit-button:hover {
  background-color: #2f71e5;
}

.modal__submit-button:disabled {
  background-color: rgba(230, 230, 230, 1);
  color: rgba(190, 190, 190, 1);
  cursor: not-allowed;
}

.modal__button-text {
  border-width: 0;
  padding: 0;
  background-color: #fff;
  color: #8cace5;
  font-weight: 500;
  cursor: pointer;
}

.modal__button-text:hover {
  color: #2f71e5;
}

.modal__button-text-invisible {
  visibility: hidden;
}

.modal__form-error-text {
  color: #e52f2f;
  align-self: flex-start;
  height: 25px;
  font-size: 14px;
}

.modal__form-city-options {
  padding: 0;
}

.modal__form-city-option-item {
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.modal__form-city-option-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.modal__form-city-option-warning {
  color: #e52f2f;
  font-size: 14px;
}

.modal__form-textarea {
  padding: 24px 24px;
}

.modal__image {
  width: 100%;
  height: 400px;
  border-radius: 16px;
  aspect-ratio: 1;
  object-fit: cover;
}

@media screen and (max-width: 640px) {
  .modal__content {
    padding: 20px;
  }
}
