.profile {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: start;
}

.sideBar {
  display: flex;
  flex-direction: column;
  font-family: "Inter-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
  align-items: start;
}

.sideBar__name {
  font-family: "Inter-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI Bold",
    Roboto, "Helvetica Neue Bold", Arial, sans-serif;
  font-size: 20px;
}

.sidebar__button {
  border-width: 0;
  background-color: #fff;
  cursor: pointer;
  padding: 0;
  margin-top: 20px;
}

.sidebar__button-logout {
  color: #2f71e5;
}

.petsSection__title {
  font-family: "Inter-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.petsSection__items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

@media screen and (max-width: 900px) {
  .profile {
    grid-template-columns: 1fr;
  }

  .sideBar {
    border-bottom: #b6bcbf 2px solid;
    align-items: center;
  }

  .petsSection__caption {
    margin-top: 10px;
  }
}
