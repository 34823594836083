.newsCard {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: #b6bcbf 1px solid;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: "Inter-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.newsCard__top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.newsCard__caption {
  font-family: "Inter-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI Bold",
    Roboto, "Helvetica Neue Bold", Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.newsCard__date {
  color: #b6bcbf;
}
.newsCard__text {
  margin-top: 5px;
  text-align: left;
}
.newsCard__author {
  font-family: "Inter-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI Bold",
    Roboto, "Helvetica Neue Bold", Arial, sans-serif;
  color: #b6bcbf;
  margin-top: 5px;
}
